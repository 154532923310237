<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="brand-logo auth-bg">
        <logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 overflow-hidden bg-white background-no-repeat bg-cover"
        :style="`background-image: url(${imgUrl}); background-position: center;`"
      />
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-lg-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('auth.register.header') }}
          </b-card-title>
          <b-card-text
            v-if="user.type"
            class="mb-2"
          >
            {{ $t(`auth.register.text-${user.type}`) }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">

              <b-form-group
                v-if="showTypeSelector"
                :label="$t('auth.register.type.title')"
                label-for="register-firstname"
                class="mb-0"
              >
                <b-row class="d-flex justify-content-between">
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :variant="user.type === 'Teacher' ? 'primary' : 'outline-primary'"
                      class="btn-block"
                      @click="user.type = 'Teacher'"
                    >
                      {{ $t('auth.register.type.teacher') }}
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :variant="user.type === 'Student' ? 'primary' : 'outline-primary'"
                      class="btn-block"
                      @click="user.type = 'Student'"
                    >
                      {{ $t('auth.register.type.student') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>

              <small
                v-if="submitClicked && !user.type"
                class="text-danger"
              >
                {{ $t('auth.register.user-role-is-required') }}
              </small>

              <!-- firstname -->
              <b-form-group
                :label="$t('auth.register.firstname')"
                label-for="register-firstname"
                class="mt-1"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.register.firstname')"
                  rules="required"
                >
                  <b-form-input
                    id="register-firstname"
                    v-model="user.firstName"
                    name="register-firstname"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="given-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- lastname -->
              <b-form-group
                :label="$t('auth.register.lastname')"
                label-for="register-lastname"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.register.lastname')"
                  rules="required"
                >
                  <b-form-input
                    id="register-lastname"
                    v-model="user.lastName"
                    name="register-lastname"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="family-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('auth.your-email')"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.your-email')"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="user.email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone -->
              <b-form-group
                :label="$t('auth.register.phone')"
                label-for="register-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.register.phone')"
                  rules="required"
                >
                  <vue-tel-input
                    v-model="user.phoneNumber"
                    mode="international"
                    default-country="PL"
                    :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
                    :input-options="{ placeholder: '', showDialCode: false }"
                    :valid-characters-only="true"
                    autocomplete="chrome-off"
                    :class="{ 'border-danger': !phoneNumberValid || !!errors.length }"
                    @validate="handlePhoneNumberValidation"
                  />
                  <small
                    v-if="!!errors.length"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                  <small
                    v-else-if="!phoneNumberValid"
                    class="text-danger"
                  >{{ $t('validations.messages.phone-number-invalid') }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('auth.password')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.password')"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="new-password"
                      v-model="user.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="checkbox-1"
                  :rules="{ required: { allowFalse: false }}"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ $t('auth.register.i-agree') }}
                    <b-link
                      v-b-modal.modal
                      href="https://totedi.com/legal/regulamin.pdf"
                      target="_blank"
                    >{{ $t('auth.register.regulations') }}</b-link>
                    {{ $t('shared.and') }}
                    <b-link
                      v-b-modal.modal
                      href="https://totedi.com/privacy-policy"
                      target="_blank"
                    >{{ $t('auth.register.privacy-policy') }}</b-link>
                  </b-form-checkbox>
                  <small
                    v-if="errors.length"
                    class="text-danger"
                  >
                    {{ $t('auth.register.acceptance-is-required') }}
                  </small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="processing"
                @click.prevent="submitClicked = true; register()"
              >
                <b-spinner
                  v-show="processing"
                  small
                />
                {{ $t('auth.register.sign-up') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('auth.already-have-account') }}</span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{ $t('auth.log-in') }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <!--
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
          -->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import store from '@/store'
import Logo from '@/views/_partials/Logo'
import router from '@/router'

dayjs.extend(utc)
dayjs.extend(timezone)

require('cleave.js/dist/addons/cleave-phone.pl')

export default {
  components: {
    Logo,
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      showTypeSelector: true,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phoneNumber: '',
        type: null,
        timezone: dayjs.tz.guess(),
      },
      sent: false,
      processing: false,
      submitClicked: null,
      sideImg: require('@/assets/images/pages/lessons/lesson_03.jpg'),
      // validation
      required,
      email,
      phoneNumberValid: true,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        // return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeMount() {
    if (this.$route.params.type) {
      this.user.type = this.$route.params.type.charAt(0).toUpperCase() + this.$route.params.type.slice(1)
      this.showTypeSelector = false
    }

    this.user.firstName = this.$route.query.firstName ?? ''
    this.user.lastName = this.$route.query.lastName ?? ''
    this.user.email = this.$route.query.email ?? ''
    this.user.phoneNumber = this.$route.query.phoneNumber ?? ''
    this.user.timezone = dayjs.tz.guess()

    this.isUserLoggedIn()
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.registerForm.reset()
    })
  },
  methods: {
    isUserLoggedIn() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$router.push('/')
        return true
      }
      this.loading = false
      return false
    },
    handlePhoneNumberValidation(phoneObject) {
      this.phoneNumberValid = !!phoneObject.valid || !phoneObject.formatted
    },
    resolveRegisterAction() {
      if (this.$route.query.organization) {
        return this.$store.dispatch(
          'auth/registerInOrganization',
          { organizationId: this.$route.query.organization, data: this.user },
        )
      }
      return this.$store.dispatch('auth/register', { data: this.user })
    },
    register() {
      if (this.isUserLoggedIn()) {
        return
      }

      this.$refs.registerForm.validate()
        .then(valid => {
          this.submitClicked = true
          if (!valid || !this.phoneNumberValid || !this.user.type) {
            return false
          }
          this.processing = true
          this.resolveRegisterAction()
            .then(() => {
              window.toast.notify.success(this.$t('auth.register.account-created'))
              router.push({ name: 'login' })
            })
            .catch(response => {
              if (response.messageFormat === 'Field: %s, message: %s') {
                window.toast.notify.warning(
                  this.$t('validations.messages.Validation Failed'),
                  this.$t(`validations.messages.${response.messageArgs[1]}`),
                  {
                    duration: 15000,
                  },
                )
                router.push({ name: 'login' })
              } else {
                window.toast.notify.danger(this.$t('auth.register.errors.other'))
              }
            })
            .finally(() => {
              this.processing = false
            })

          return true
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper .brand-logo {
  border-radius: 0.357rem;
  img {
    width: 200px;
    height: inherit;
    margin: 30px;
  }
}

.vti__dropdown-list {
  min-width: 300px;
}
</style>
